import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useGeneralTerms(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(GENERAL_TERMS_QUERY, variables);
  const terms = computed(() => result?.value?.termsNew?.nodes?.[0] || []);

  onError((err) => {
    console.error('useTerms', err);
    error();
  });

  return {
    terms,
    loading,
    refetch,
  };
}

export const useCreateGeneralTerms = (errorCallback) => {
  const { error } = useNotification();

  const { mutate, loading, onError, onDone } = useMutation(GENERAL_TERM_CREATE_MUTATION);

  onError((err) => {
    console.error('useCreateGeneralTerm', err);
    error();
    errorCallback();
  });

  return {
    createGeneralTerms: mutate,
    loading,
    onDone,
  };
};

export const useUpdateGeneralTerms = (errorCallback) => {
  const { error } = useNotification();

  const { mutate, loading, onError, onDone } = useMutation(GENERAL_TERM_UPDATE_MUTATION);

  onError((err) => {
    console.error('useUpdateGeneralTerms', err);
    error();
    errorCallback();
  });

  return {
    updateGeneralTerms: mutate,
    loading,
    onDone,
  };
};

export const GENERAL_TERMS_QUERY = gql`
  query generalTerms($type: TermTypeNew, $supplierId: ID, $businessId: ID) {
    termsNew(type: $type, supplierId: $supplierId, businessId: $businessId) {
      nodes {
        id
        supplierId
        fromDate
        customerNumber
      }
    }
  }
`;

export const GENERAL_TERM_UPDATE_MUTATION = gql`
  mutation generalTermUpdateMutation($termId: ID!, $updatePayload: TermUpdateInputNew!) {
    termUpdateNew(termId: $termId, termUpdateInput: $updatePayload) {
      id
      supplierId
      fromDate
      customerNumber
    }
  }
`;

export const GENERAL_TERM_CREATE_MUTATION = gql`
  mutation generalTermCreateMutation($createPayload: TermCreateInputNew!) {
    termCreateNew(termCreateInput: $createPayload) {
      id
      supplierId
      fromDate
      customerNumber
    }
  }
`;
