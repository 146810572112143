<template>
  <div v-loading="loading" class="order-terms pb-5">
    <div class="order-terms-title">
      <h3>{{ $t('orderTerms.title') }}</h3>
      <Button v-if="hasEditOrderTermPermission" type="text" class="text-primary p-0" @click="toggleModal">
        {{ $t('orderTerms.edit') }}
      </Button>
    </div>
    <template v-if="contactInfoItems.length === 0 || scheduleItems.length === 0">
      <ul class="list-group">
        <li class="list-group-item px-4 py-2">
          <p class="row d-flex flex-column align-items-center">{{ $t('orderTerms.missingData') }}</p>
        </li>
      </ul>
    </template>
    <template v-else>
      <OrderTermInfo :title="$t('orderTerms.contactInfo.title')" :items="contactInfoItems" />
      <OrderTermInfo :title="$t('orderTerms.schedule.title')" :items="scheduleItems" />
      <OrderTermInfo
        v-if="extraSettings.length > 0"
        :title="$t('orderTerms.extraSettings.title')"
        :items="extraSettings"
        :is-rtl="true"
      />
    </template>
    <OrderTermsModal
      v-if="showModal"
      :data="supplierOrderTerms"
      :submit-loading="updateLoading || createLoading"
      @on-cancel="onCancel"
      @on-submit="onSubmit"
    />
  </div>
</template>
<script>
import { ref, getCurrentInstance, computed } from 'vue';

import { Button } from '@/modules/core';
import { useCurrency } from '@/locale/useCurrency';
import { useUserPermissions } from '@/modules/auth';
import { USER_NEW_PERMISSIONS_LIST } from '@/permissions';

import OrderTermsModal from './components/OrderTermsModal.vue';
import OrderTermInfo from './components//OrderTermInfo.vue';
import { formatCurrencyOrDash } from '../../purchaseManagementFormatters';
import { useSupplierOrderTerms } from './compositions/useOrderTerms';
import { useUpdateOrderTerms } from './compositions/useUpdateOrderTerms';
import { useCreateOrderTerms } from './compositions/useCreateOrderTerms';

export default {
  components: { Button, OrderTermsModal, OrderTermInfo },
  props: { supplierId: { type: String, required: true }, businessId: { type: String, required: true } },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const showModal = ref(false);
    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const hasEditOrderTermPermission = isUserPermittedForActiveTenant(USER_NEW_PERMISSIONS_LIST.EDIT_ORDER_TERMS);
    const { currencyFormat } = useCurrency();

    const { supplierOrderTerms, loading, refetch } = useSupplierOrderTerms(
      computed(() => ({
        supplierId: props.supplierId,
        businessId: props.businessId,
        first: 25,
        after: 0,
      }))
    );

    const { createOrderTerms, onDone: onCreateDone, loading: createLoading } = useCreateOrderTerms(refetch);

    const { updateOrderTerms, onDone: onUpdateDone, loading: updateLoading } = useUpdateOrderTerms(refetch);

    const contactInfoItems = computed(() => {
      const relevantNode = supplierOrderTerms.value.nodes[0];
      if (!relevantNode) return [];

      return relevantNode.contactInfo.map((contactInfo) => {
        const textForEnabledMethodWithoutValue =
          !contactInfo.address && root.$t(`orderTerms.contactInfo.${contactInfo.method}Enabled`);

        return {
          title: root.$t(`orderTerms.contactInfo.${contactInfo.method}`),
          value: contactInfo.address || textForEnabledMethodWithoutValue,
        };
      });
    });

    const scheduleItems = computed(() => {
      const relevantNode = supplierOrderTerms.value.nodes[0];
      if (!relevantNode) return [];

      return relevantNode.schedules.map((schedule) => {
        return {
          title: `${root.$t(`orderTerms.schedule.weekDays.long.${schedule.deliveryArrivalTime.dayOfWeek}`)}, ${
            schedule.deliveryArrivalTime.timeOfDay
          }`,
          value: root.$t('orderTerms.schedule.orderCutOff', {
            day: root.$t(`orderTerms.schedule.weekDays.long.${schedule.orderCutOffTime.dayOfWeek}`),
            time: schedule.orderCutOffTime.timeOfDay,
          }),
        };
      });
    });

    const extraSettings = computed(() => {
      const relevantNode = supplierOrderTerms.value.nodes[0];
      if (!relevantNode || !relevantNode.minimumOrderAmount > 0) return [];

      return [
        {
          title: root.$t('orderTerms.extraSettings.minimumOrderAmount'),
          value: formatCurrencyOrDash(relevantNode.minimumOrderAmount, currencyFormat.value),
        },
      ];
    });

    const orderTermId = computed(() =>
      supplierOrderTerms.value.nodes.length > 0 ? supplierOrderTerms.value.nodes[0].id : undefined
    );

    const toggleModal = () => {
      showModal.value = !showModal.value;
    };

    const onCancel = () => {
      toggleModal();
    };

    const onSubmit = ({ schedules, contactInfo, minimumOrderAmount }) => {
      if (orderTermId.value) {
        if (schedules.length > 0 || contactInfo.length > 0)
          updateOrderTerms({
            orderTermId: orderTermId.value,
            updatePayload: {
              businessId: props.businessId,
              supplierId: props.supplierId,
              contactInfo,
              schedules,
              minimumOrderAmount,
            },
          });
        else toggleModal();
      } else {
        if (schedules.length > 0 || contactInfo.length > 0) {
          createOrderTerms({
            businessId: props.businessId,
            supplierId: props.supplierId,
            createPayload: {
              contactInfo: contactInfo.map(({ method, address }) => ({ method, address })),
              schedules: schedules.map(({ deliveryArrivalTime, orderCutOffTime }) => ({
                deliveryArrivalTime,
                orderCutOffTime,
              })),
              minimumOrderAmount,
            },
          });
        }
      }
    };

    onUpdateDone(() => {
      toggleModal();
      refetch();
    });

    onCreateDone(() => {
      toggleModal();
      refetch();
    });

    return {
      loading,
      showModal,
      extraSettings,
      scheduleItems,
      contactInfoItems,
      supplierOrderTerms: computed(() => supplierOrderTerms.value.nodes[0]),
      updateLoading,
      createLoading,
      hasEditOrderTermPermission,
      onSubmit,
      onCancel,
      toggleModal,
    };
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';
.order-terms {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.order-terms-title {
  display: flex;
  width: 100%;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
}
</style>
