<template>
  <div>
    <div class="d-flex align-items-center mb-2">
      <p class="fw-bold">{{ title }}</p>
    </div>
    <ul class="list-group">
      <li v-for="(item, index) in items" :key="`order-term-${index}`" class="list-group-item px-4 py-2">
        <div class="row d-flex align-items-center">
          <div class="col-2">
            <p class="fw-bold">
              {{ item.title }}
            </p>
          </div>
          <div class="col d-flex justify-content-between align-items-center">
            <p :dir="isRtl ? 'rtl' : 'ltr'">
              {{ item.value }}
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    title: { type: String, required: true },
    items: { type: Array, required: true },
    isRtl: { type: Boolean, default: false },
  },
  setup() {},
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';
</style>
