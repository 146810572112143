<template>
  <div v-loading="loading" class="general-terms">
    <div class="general-terms-title">
      <h3>{{ $t('terms.supplierTerms.generalTerms.title') }}</h3>
      <Button v-if="hasEditGeneralTermsPermission" type="text" class="text-primary p-0" @click="toggleModal">
        {{ $t('terms.supplierTerms.generalTerms.edit') }}
      </Button>
    </div>

    <ul class="list-group">
      <li class="list-group-item px-4 py-2">
        <div class="row d-flex align-items-center">
          <div class="col-2">
            <p class="fw-bold" :class="$direction === 'ltr' ? 'me-7' : 'ms-7'">
              {{ $t('terms.supplierTerms.generalTerms.customerNumber') }}
            </p>
          </div>
          <div class="col d-flex justify-content-between align-items-center">
            <p v-if="generalTerms.customerNumber">{{ generalTerms.customerNumber }}</p>
            <p v-else>-</p>
          </div>
        </div>
      </li>
    </ul>

    <GeneralTermsModal
      v-if="showModal"
      :data="generalTerms"
      :submit-loading="updateLoading || createLoading"
      @on-cancel="onCancel"
      @on-submit="onSubmit"
    />
  </div>
</template>
<script>
import { ref, computed } from 'vue';

import { Button } from '@/modules/core';
import { useUserPermissions } from '@/modules/auth';
import { USER_NEW_PERMISSIONS_LIST } from '@/permissions';

import { useCreateGeneralTerms, useGeneralTerms, useUpdateGeneralTerms } from './compositions/generalTerms';
import GeneralTermsModal from './components/GeneralTermsModal.vue';

export default {
  name: 'GeneralTerms',
  components: { Button, GeneralTermsModal },
  props: { supplierId: { type: String, required: true }, businessId: { type: String, required: true } },
  setup(props) {
    const showModal = ref(false);
    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const hasEditGeneralTermsPermission = isUserPermittedForActiveTenant(
      USER_NEW_PERMISSIONS_LIST.PURCHASE_PAYMENT_TERMS
    );

    const {
      terms: generalTerms,
      loading,
      refetch: refetchTerms,
    } = useGeneralTerms(
      computed(() => ({
        type: 'general',
        supplierId: props.supplierId,
        businessId: props.businessId,
      }))
    );

    const { createGeneralTerms, onDone: onCreateDone, loading: createLoading } = useCreateGeneralTerms(refetchTerms);

    const { updateGeneralTerms, onDone: onUpdateDone, loading: updateLoading } = useUpdateGeneralTerms(refetchTerms);

    const generalTermsId = computed(() => generalTerms.value.id);

    const toggleModal = () => {
      showModal.value = !showModal.value;
    };

    const onCancel = () => {
      toggleModal();
    };

    const onSubmit = ({ customerNumber }) => {
      const fromDate = new Date().toISOString().split('T')[0];

      if (generalTermsId.value) {
        updateGeneralTerms({
          termId: generalTermsId.value,
          updatePayload: {
            type: 'general',
            businessId: props.businessId,
            supplierId: props.supplierId,
            customerNumber,
            fromDate,
          },
        });
      } else {
        createGeneralTerms({
          createPayload: {
            type: 'general',
            businessId: props.businessId,
            supplierId: props.supplierId,
            customerNumber,
            fromDate,
          },
        });
      }
    };

    onUpdateDone(() => {
      toggleModal();
      refetchTerms();
    });

    onCreateDone(() => {
      toggleModal();
      refetchTerms();
    });

    return {
      loading,
      showModal,
      hasEditGeneralTermsPermission,
      onSubmit,
      onCancel,
      toggleModal,
      generalTerms,
      createLoading,
      updateLoading,
    };
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';
.general-terms {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.general-terms-title {
  display: flex;
  width: 100%;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
}
</style>
