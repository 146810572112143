<template>
  <el-dialog top="35vh" :visible="true" custom-class="rounded" width="600px" @close="onCancel">
    <h2 slot="title">{{ $t('terms.supplierTerms.generalTerms.generalTermsModal.title') }}</h2>
    <el-form ref="form" :model="formValues" :rules="rules">
      <div class="general-term-form">
        <el-form-item
          :label="$t('terms.supplierTerms.generalTerms.generalTermsModal.form.customerNumber')"
          prop="customerNumber"
          class="mb-0 w-50"
        >
          <el-input v-model="formValues.customerNumber" />
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer">
      <Button type="secondary" @click="onCancel">{{ $t('commons.cancel') }}</Button>
      <Button type="primary" :loading="submitLoading" @click="onSubmit">{{ $t('commons.save') }}</Button>
    </div>
  </el-dialog>
</template>
<script>
import { ref, reactive, toRefs, getCurrentInstance } from 'vue';

import { Button } from '@/modules/core';

export default {
  name: 'GeneralTermsModal',
  components: { Button },
  props: {
    data: { type: Object, default: () => ({}) },
    submitLoading: { type: Boolean, default: false },
  },
  emits: ['on-cancel', 'on-submit'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;

    const getInitialFormValues = (data) => {
      const formValuesToUpdate = {
        customerNumber: data.customerNumber || '',
      };

      return formValuesToUpdate;
    };

    const form = ref(null);
    const watchableData = toRefs(props).data;
    const formValues = reactive({ data: getInitialFormValues(watchableData.value) });

    const onCancel = () => {
      Object.assign(formValues.data, getInitialFormValues(watchableData.value));

      emit('on-cancel');
    };

    const onSubmit = () => {
      form.value
        .validate()
        .then(() => {
          emit('on-submit', formValues.data);
        })
        .catch((err) => console.log(err));
    };

    const rules = {
      customerNumber: [
        {
          pattern: /^[0-9]*$/,
          message: root.$t('terms.supplierTerms.generalTerms.generalTermsModal.form.validationErrors.customerNumber'),
          trigger: 'blur',
        },
      ],
    };

    return {
      form,
      formValues: formValues.data,
      onCancel,
      onSubmit,
      rules,
    };
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.general-term-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: $typography-primary;
}
</style>
